export const AboutData = [
  {
    title: "Innovate",
    description: (
      <>
        Embracing innovation at every step, we use cutting-edge technology to
        elevate your business in the digital realm.
      </>
    ),
    iconName: "fas fa-lightbulb"
  },
  {
    title: "Bridge",
    description: (
      <>
        Connecting your business strategy with technological advancement, we
        create seamless solutions for growth and efficiency.
      </>
    ),
    iconName: "fas fa-globe"
  },
  {
    title: "Empower",
    description: (
      <>
        Empowering your business with technology, we equip you with tools for
        informed decisions and long-term success.
      </>
    ),
    iconName: "fas fa-hand-sparkles"
  },
];
