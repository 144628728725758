import "./css/VideoPlayer.css";

const HomepageVideo = () => {
  return (
    <div className="elementor-background-video-container">
      <div className="video-wrapper">
        <video
          className="elementor-background-video-hosted elementor-html5-video"
          src="videos/LandingPageVideo.mp4"
          autoPlay
          muted
          loop
        ></video>
      </div>
      {/* <div className="text-overlay">
        <h1>WELCOME TO NEXIC</h1>
        <p>THE NEXT IN IT EXCELLENCE</p>
      </div> */}
    </div>
  );
};

export default HomepageVideo;

{
  /* <div className="video-container">
<div className="video-wrapper">
        <ReactPlayer
           url={"videos/LandingPageVideo.mp4"}
          controls={false} // Disable default controls
           playing={true} // Set video to play by default
          width="100%"
          height="700px"
          style={{objectFit: "cover" }}
          volume={0} // Mute the video
       config={{ file: { attributes: { controlsList: 'nodownload' } } }} // Hide download button
       />
</div> 
</div> */
}
