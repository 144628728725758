import {
  CompanyContactDetail,
  CompanyEmail,
  CompanyLocation,
  CompanyPOInfo,
  FacebookLink,
  LinkedInLink,
  MailTo,
  MyCompany,
  TwitterLink,
} from "utils/Constants";
import "./css/Footer.css";
import { Row, Col } from "reactstrap";
import {
  FaArrowAltCircleUp,
  FaEnvelope,
  FaFacebook,
  FaMapMarkerAlt,
  FaPhone,
} from "react-icons/fa";
import { GrTwitter, GrLinkedin } from "react-icons/gr";
import { SiGmail } from "react-icons/si";

function Footer() {
  return (
    <div className="footerDiv">
      <div className="container">
        <Row className="equalSpace">
          <Col md={4} sm={6} xs={12}>
            <div className="px-5 pt-5">
              <p className="footerTitle">QUICK LINKS</p>
              <span className="footerFont">
                <p>
                  <a href="/">HOME</a>
                </p>
                {/* <hr /> */}
                <p>
                  <a href="/services">SERVICES</a>
                </p>
                {/* <hr /> */}
                <p>
                  <a href="/about">ABOUT US</a>
                </p>
                {/* <hr /> */}
                <p>
                  <a href="/careers">CARRERS</a>
                </p>
                {/* <hr /> */}
                <p>
                  <a href="/contact">CONTACT</a>
                </p>
                {/* <hr /> */}
              </span>
            </div>
          </Col>
          <Col md={4} sm={6} xs={12}>
            <div className="px-5 pt-5">
              <p className="footerTitle">Get In Touch</p>
              <span className="footerFont">
                <p>
                  <FaMapMarkerAlt className="icon" />{CompanyPOInfo}, {CompanyLocation}
                </p>
                <p>
                  <FaPhone className="icon" />  {CompanyContactDetail}
                </p>
                <p>
                  <FaEnvelope className="icon" /> {CompanyEmail}
                </p>
                <br />
              </span>
            </div>
          </Col>
          <Col md={4} sm={12} xs={12}>
            <div className="px-5 pt-5">
              <p className="footerTitle">Follow Us</p>
              <span className="footerFont">
                <Col>
                  <a className="m-1" href={FacebookLink} color="red">
                    <FaFacebook className="icon" />
                  </a>
                  <a className="m-1" href={TwitterLink}>
                    <GrTwitter className="icon" />
                  </a>
                  <a className="m-1" href={LinkedInLink}>
                    <GrLinkedin className="icon" />
                  </a>
                  <a className="m-1" href={MailTo}>
                    <SiGmail className="icon" />
                  </a>
                </Col>
              </span>
            </div>
          </Col>
        </Row>
        <Row className="equalSpacing">
          <Col></Col>
          <Col className="footerFont text-center mt-3">
            <div className="px-5">
              <p>
                {" "}
                <a href="/">© {MyCompany}</a>
              </p>
            </div>
          </Col>
          <Col>
            <button
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              className="go-to-top-btn"
            >
              <FaArrowAltCircleUp />
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Footer;
