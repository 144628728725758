import { TitleContainer } from "components/TitleContainer";
import { Fragment } from "react";
import { Col, Row, Container } from "reactstrap";
import { isCareers, NoVanancy } from "utils/Constants";
import "./css/Careers.css";

export const Careers = () => {
  const emptySpace = [];
  for (let i = 0; i < 20; i++) {
    emptySpace.push(
      <Fragment>
        <br></br>
      </Fragment>
    );
  }

  const isHiring = (
    <Container className="marginCustom">
      <Row>
        <Col className="centered">
          <img
            alt="NoVacancy"
            src="/no-vacancy.png"
            height="100%"
            width="50%"
          ></img>
        </Col>
        <Col className="centered">{NoVanancy}</Col>
      </Row>
    </Container>
  );

  return (
    <div>
      <TitleContainer imageSrc="/careers.png" title="Careers" />
      {isCareers ? isHiring : <>{emptySpace}</>}
    </div>
  );
};
