import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
} from "reactstrap";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdMarkEmailRead, MdLocationOn } from "react-icons/md";
import React, { ReactNode, useState, useRef } from "react";
import emailjs from "@emailjs/browser";

import "./css/Contact.css";

import {
  CompanyContactDetail,
  CompanyEmail,
  CompanyLocation,
  CompanyPOInfo,
  MailTo,
  PublicKey,
  ServiceId,
  TemplateId,
} from "utils/Constants";
import { TitleContainer } from "components/TitleContainer";

function Contact() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const user_name = e.currentTarget.elements.namedItem(
      "user_name"
    ) as HTMLInputElement;
    const user_email = e.currentTarget.elements.namedItem(
      "user_email"
    ) as HTMLInputElement;
    const subject = e.currentTarget.elements.namedItem(
      "subject"
    ) as HTMLInputElement;
    const message = e.currentTarget.elements.namedItem(
      "message"
    ) as HTMLInputElement;

    // Simple form validation
    if (
      !user_name.value ||
      !user_email.value ||
      !subject.value ||
      !message.value
    ) {
      setErrorMessage("We were unable to process your form, please try again.");
      setSuccessMessage(null); // Clear the success message if present
      return;
    }

    try {
      const result = await emailjs.sendForm(
        ServiceId,
        TemplateId,
        formRef.current as HTMLFormElement,
        PublicKey
      );
      console.log("Success", result);
      setErrorMessage(null); // Clear the error message on success
      setSuccessMessage("Your message has been sent successfully!"); // Set the success message
      formRef.current?.reset(); // Reset the form fields
    } catch (error) {
      console.error("Error", error);
      setErrorMessage("We were unable to process your form, please try again.");
      setSuccessMessage(null); // Clear the success message if present
    }
  };

  return (
    <div>
      <TitleContainer imageSrc="/carousal1.png" title="Contact Us" />
      <Container fluid="sm" className="centered margin4">
        <div className="shadow p-3 mb-5 bg-white rounded">
          <h1>Let's Talk!</h1>
          <Row xs={1} md={2}>
            <ContactDetail />
            <Col>
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              {successMessage && <Alert color="success">{successMessage}</Alert>}
              <Form onSubmit={sendEmail} innerRef={formRef}>
                <FormGroup>
                  <Label>
                    Full Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    id="user_name"
                    name="fullName"
                    placeholder=""
                    type="text"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Email <span className="text-danger">*</span>
                  </Label>
                  <Input
                    id="user_email"
                    name="Email"
                    placeholder=""
                    type="email"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Subject</Label>
                  <Input
                    id="subject"
                    name="subject"
                    placeholder=""
                    type="text"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Message</Label>
                  <Input
                    id="message"
                    name="message"
                    placeholder=""
                    type="textarea"
                    style={{ height: "150px" }}
                  />
                </FormGroup>
                <Button block color="primary" size="lg">
                  Send Message
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export const ContactDetail = () => {
  return (
    <Col>
      <Row xs={1}>
        <Col>
          Hate Forms? Instead send us an{" "}
          <a
            className="m-1"
            href={MailTo}
            style={{ display: "contents", textDecoration: "none" }}
          >
            email
          </a>
          {"."}
          <br />
        </Col>
        <FormElements
          iconType={<BiSolidPhoneCall />}
          children={CompanyContactDetail}
        />
        <FormElements iconType={<MdMarkEmailRead />} children={CompanyEmail} />
        <FormElements iconType={<MdLocationOn />}>
          <span /> {CompanyPOInfo}, {CompanyLocation}
        </FormElements>
      </Row>
    </Col>
  );
};

interface FormElementProps {
  iconType: JSX.Element;
  children: ReactNode;
}

const FormElements = ({ iconType, children }: FormElementProps) => {
  return (
    <Col className="formEqualSpacing">
      <Row xs={2}>
        <Col xs={1}>{iconType}</Col>
        <Col>{children}</Col>
      </Row>
    </Col>
  );
};

export default Contact;
