import React from "react";

export const ServiceData = [
  {
    id: "staffing",
    title: "IT Staffing & Consulting",
    imgsrc: "staffing.png",
    description: (
      <>
        Manpower is the biggest asset for any organization. It’s important to
        find the talent which fits perfectly into your company’s vision and
        goals. For years Nexic has found the very best talent in IT services
        through a national and global network of recruiters to cater the needs
        of organizations of all sizes.
        <br />
        We identify and understand the business goals of the organization to
        provide accurate consulting for staffing fulfillment. We have helped
        organizations contain cost, reduce overtime, reduce turnover or increase
        the productivity of your workforce, just through proper structuring of
        the workforce
      </>
    ),
  },
  {
    id: "testing",
    title: "Testing & QA",
    imgsrc: "testing.png",
    description: (
      <>
        Nexic helps deliver quality products that benefit end users with the
        best experience. Our quality assurance and testing ensures your product
        works perfectly and enhances your customers’ life. We establish quality
        standards, goals and methodologies. We carefully implement each step to
        take in order to increase the quality of your software, so that it meets
        top level performance requirements.
      </>
    ),
  },
  {
    id: "training",
    title: "IT Training Services",
    imgsrc: "training.png",
    description: (
      <>
        Nexic has a range of programs to train the next gen of IT professionals
        or just individuals looking to enhance their skill set. Beginning from
        simple skills like managing computer networks, developing software, IT
        security and cloud platform technologies. Take part in building the
        future with expertise in IT development and management. Learn
        specialized skills like software designing, parallel programming,
        quality assurance, testing, AI and IT security with our online and
        offline training programs.
        <br />
        The trainers are handpicked experienced professionals who are working in
        the industry with live project experience. The flexible timings and
        training content design allows seamless learning experience for each
        individual and equips them with the ability to build projects on their
        own.
      </>
    ),
  },
  {
    id: "devops",
    title: "Devops Services",
    imgsrc: "devops.png",
    description: (
      <>
        Faster delivery speeds from a stable, reliable environment through
        enhanced collaboration between development and operations with DevOps.
        Streamline and automate the flow of your product’s lifecycle, from
        design and development to deployment & operations. Our customized DevOps
        strategies enable you to successfully migrate to agile Cloud
        infrastructure. Our solutions automate away inefficiencies and improve
        the quality and reliability of software products.
      </>
    ),
  },
  {
    id: "development",
    title: "Development Services",
    imgsrc: "development.png",
    description: (
      <>
        Custom website development for businesses that need fast, feature-rich,
        fabulous looking websites. Expert team with proficiency in multiple
        website development technologies to design and develop websites of all
        shapes and sizes.
      </>
    ),
  },
  {
    id: "career",
    title: "Career Placement",
    imgsrc: "career.png",
    description: (
      <>
        Career Placement Services from Nexic
        <li>Large Client portfolio</li>
        <li> Multiple Successful Candidate Placed</li>
        <li> Quick initial screening</li>
        <li> Global placement opportunities</li>
        <li> Expert guidance for career building </li>
        <li>Feedback and continuous support</li>
      </>
    ),
  },
];
