import { Card, CardText, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

import "./css/Card.css";

interface Props {
  title: string;
  id: string;
  imgSrc: string;
  content: JSX.Element;
}

const Cards = ({ title, id, imgSrc, content }: Props) => {
  return (
    <Card className="cardService mb-3">
      <CardTitle center className="h3 mx-3 mt-3" style={{ color: "#154360" }}>
        {title}
      </CardTitle>
      <CardBody>
        <img
          width="100%"
          height="250px"
          src={process.env.PUBLIC_URL + imgSrc}
          alt="serviceImg"
        />
        <hr />
        <CardText>{content}</CardText>
        <Link to={`/services#${id}`}>Read more</Link>
      </CardBody>
    </Card>
  );
};

export default Cards;
