import { TitleContainer } from "components/TitleContainer";
import { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { ServiceData } from "utils/ServiceData";

import "./css/Service.css";

export const Service = () => {
  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        const offset = 273; // Adjust the offset value based on your preference
        const elementPosition = element.offsetTop - offset;
        window.scrollTo({
          top: elementPosition,
          behavior: "smooth",
        });
      }
    }
  }, []);
  return (
    <div>
      <TitleContainer imageSrc="/service.png" title="Our Services" />

      {/* <StickyNav /> */}

      <div className="mt-5">
        {ServiceData.map((value, index) => {
          return (
            <div id={value.id} key={value.id}>
              <ServiceComponent
                key={value.id}
                id={value.id}
                title={value.title}
                description={value.description}
                index={index}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

// const StickyNav = () => {
//   const [isSticky, setIsSticky] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       const offset = window.scrollY;
//       const mainNavbarHeight = 65; // Adjust this value based on your main navbar's height

//       // Check if the main navbar is out of the viewport
//       setIsSticky(offset > mainNavbarHeight);
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const scrollToElement = (id: string) => {
//     const element = document.getElementById(id);
//     if (element) {
//       const offset = isSticky ? 110 : 233; // Adjust the offset value based on your preference
//       const elementPosition = element.offsetTop - offset;
//       window.scrollTo({
//         top: elementPosition,
//         behavior: "smooth",
//       });
//     }
//   };

//   return (
//     <div className={` ${isSticky ? "sticky" : "margin4"}`}>
//       <Navbar className="serviceNav" expand="sm" color="dark" dark>
//         <Nav className="mx-auto" navbar>
//           <NavItem>
//             <NavLink className="navlinkMargin">
//               <a href="#a" onClick={() => scrollToElement("staffing")}>
//                 IT Staffing &amp; Consulting
//               </a>
//             </NavLink>
//           </NavItem>
//           <NavItem>
//             <NavLink className="navlinkMargin">
//               <a href="#a" onClick={() => scrollToElement("testing")}>
//                 Testing &amp; QA
//               </a>
//             </NavLink>
//           </NavItem>
//           <NavItem>
//             <NavLink className="navlinkMargin">
//               <a href="#a" onClick={() => scrollToElement("training")}>
//                 IT Training Services
//               </a>
//             </NavLink>
//           </NavItem>
//           <NavItem>
//             <NavLink className="navlinkMargin">
//               <a href="#a" onClick={() => scrollToElement("devops")}>
//                 Devops Services
//               </a>
//             </NavLink>
//           </NavItem>
//           <NavItem>
//             <NavLink className="navlinkMargin">
//               <a href="#a" onClick={() => scrollToElement("development")}>
//                 Development Services
//               </a>
//             </NavLink>
//           </NavItem>
//           <NavItem>
//             <NavLink className="navlinkMargin">
//               <a href="#a" onClick={() => scrollToElement("career")}>
//                 Career Placement
//               </a>
//             </NavLink>
//           </NavItem>
//         </Nav>
//       </Navbar>
//     </div>
//   );
// };

interface ServiceComponentProps {
  id: string;
  title: string;
  description: JSX.Element;
  index: number;
}

const ServiceComponent = ({
  id,
  title,
  description,
  index,
}: ServiceComponentProps) => {
  return (
    <div className="marginHorizontal" id={id}>
      <Row xs={2}>
        {index % 2 === 0 && (
          <Col className="centered ">
            <h2>{title}</h2>
          </Col>
        )}
        <Col className="centered" id={id}>
          <p>{description}</p>
        </Col>
        {index % 2 === 1 && (
          <Col className="centered ">
            <h2>{title}</h2>
          </Col>
        )}
      </Row>
      <hr className="my-5"></hr>
    </div>
  );
};
