import AboutCard from "components/AboutCard";
import { TitleContainer } from "components/TitleContainer";
import { Row, Col } from "reactstrap";
import { AboutData } from "utils/AboutData";

import "./css/About.css";

export const About = () => {
  return (
    <div>
      <TitleContainer imageSrc="/about.png" title="About" />
      <div className="mx-5 my-5">
        <AboutDescription />
        <Row className="centered-card">
          <>
            {AboutData.map((value, index) => {
              return (
                <Col sm="4" >
                  <AboutCard
                    title={value.title}
                    description={value.description}
                    iconName={value.iconName}
                  />
                </Col>
              );
            })}
          </>
        </Row>
      </div>
    </div>
  );
};

const AboutDescription = () => {
  const description = (
    <>
      At Nexic IT, we are dedicated to delivering cutting-edge IT solutions and
      exceptional service. Our journey began with a vision to bridge the gap
      between technology and businesses, growing into a trusted provider of
      comprehensive IT consulting services. Our team, composed of seasoned
      experts in various IT domains, is committed to helping our clients achieve
      their business goals through innovative strategies. We pride ourselves on
      our consistent track record, achieving over 90% client retention, largely
      due to our focus on results and customer satisfaction. With a wealth of
      experience across multiple industries, we are uniquely positioned to offer
      tailored solutions that drive success.
    </>
  );

  return (
    <div className="mx-10 my-5">
      <Row xs={1} xl={2}>
        <Col className="centered" style={{ textAlign: 'justify' }}>{description}</Col>
        <Col>
          <img
            className="shadow bg-white rounded"
            src="/about_content.png"
            alt="Description"
            width="80%"
          // height="120%"
          />
        </Col>
      </Row>
    </div>
  );
};
