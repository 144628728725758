import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import TopNav from "./components/TopNav";
import Contact from "./views/Contact";
import Footer from "./components/Footer";
import { About } from "views/About";
import { Service } from "views/Service";
import { Careers } from "views/Careers";

function App() {
  return (
    <BrowserRouter>
      <TopNav currentPage="" />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Service />} />
          <Route path="/about" element={<About />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
