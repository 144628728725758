import "./css/TitleContainer.css";

interface TitleProps {
  imageSrc: string;
  title: string;
}

export const TitleContainer = ({ imageSrc, title }: TitleProps) => {
  return (
    <div>
      <div className="defaultContainer">
        <img
          src={imageSrc}
          height="250px"
          width="100%"
          alt="Carousal"
          style={{ objectFit: "cover" }}
        />
        {/* <div className="center">{title}</div> */}
      </div>
    </div>
  );
};
