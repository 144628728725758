import { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavbarBrand,
} from "reactstrap";
import "./css/TopNav.css";
import { MyCompany } from "utils/Constants";
import { Link, useLocation } from "react-router-dom";

interface Props {
  currentPage: string;
}

const TopNav = ({ currentPage }: Props) => {
  const page = useLocation();
  const [showMenu, setShowMenu] = useState(false);

  const handleImageClick = () => {
    // Navigate to the homepage when the image is clicked
    window.location.href = "/";
  };

  return (
    <Navbar className="navStyle" expand="md" sticky="top">
      <NavbarBrand tag={Link} to="/">
        <img
          src={process.env.PUBLIC_URL + "/titleLogo.svg"}
          alt={MyCompany}
          width="250px"
          height="70px"
          style={{ imageRendering: "pixelated" }}
          className="mx-3 "
          onClick={handleImageClick}
        />
      </NavbarBrand>
      <NavbarToggler
        className="togglerMenu"
        onClick={() => setShowMenu(!showMenu)}
      />
      <Collapse isOpen={showMenu} navbar>
        <Nav className="ms-auto" navbar>
          <NavItem>
            <Link
              className={
                "navlinkColor " + (page.pathname === "/" ? "active" : "")
              }
              to="/"
            >
              Home
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className={
                "navlinkColor " +
                (page.pathname === "/services" ? "active" : "")
              }
              to="/services"
            >
              Services
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className={
                "navlinkColor " + (page.pathname === "/about" ? "active" : "")
              }
              to="/about"
            >
              About
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className={
                "navlinkColor " + (page.pathname === "/careers" ? "active" : "")
              }
              to="/careers"
            >
              Careers
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className={
                "navlinkColor " + (page.pathname === "/contact" ? "active" : "")
              }
              to="/contact"
            >
              Contact
            </Link>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default TopNav;
