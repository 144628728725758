import Card from "components/Card";
import Carousal from "components/Carousal";
import VideoPlayer from "components/VideoPlayer";
import { Row, Col } from "reactstrap";

import { ServiceData } from "utils/ServiceData";
import "./css/Home.css";

const Home = () => {
  return (
    <div>
      {/* <Carousal /> */}
      <VideoPlayer />
      <br />
      <h1 className="centered">Our Services</h1>
      <div className="container">
        <Row md={1} xl={3}>
          {ServiceData.map((value, index) => {
            return (
              <Col
                xs={11}
                sm={11}
                md={11}
                xl={4}
                key={index}
                className="centered"
              >
                <Card
                  imgSrc={value.imgsrc}
                  title={value.title}
                  content={value.description}
                  id={value.id}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default Home;
