import { Card, CardHeader, CardText, CardBody } from "reactstrap";

import "./css/AboutCard.css";

interface Props {
  title: string;
  description: JSX.Element;
  iconName: string;
}

const AboutCard = ({ title, description, iconName }: Props) => {
  return (
    <Card
      className="my-2 shadow bg-light rounded my-card card "
      color="light"
      inverse
      style={{
        width: "85%"
      }}
    >
      <CardBody>
        <div className="icon-container">
      <i className={`${iconName} fa-3x`} style={{"color": "rgb(173,0,0)", textAlign: "center"}}></i>
      </div>
        <CardText className="card-title">{title}</CardText>
        <CardText className="text-black">{description}</CardText>
      </CardBody>
    </Card>
  );
};

export default AboutCard;
